<!-- TODO Função de salvar na lista -->
<template>
  <div>
    <Header />
    <b-container>
      <div @click="$router.go(-1)">
        <ButtonReturn 
          v-bind:title="'ADICIONAR NOME NA LISTA'"/>  
      </div>
      <div>
        <FormDocument v-bind:perfil="modeProfile" />
      </div>
    </b-container>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import FormDocument from "@/components/FormDocument.vue";
import ButtonReturn from "@/components/ButtonReturn.vue"

export default {
  name: "EditDocument",
  components: {
    Header,
    FormDocument,
    ButtonReturn
  },
  props: {},
  created: async function () {},
  data() {
    return {    
      showAlert:false,
      document: this.$route.params.document,
      modeProfile: this.$route.params.modeProfile,
      userData: this.$root.rootObject,
      title: "ADICIONAR NOME NA LISTA",
    };
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
  },
  methods: {
  },
};
</script>
<style>
  .icons {
    margin-left: 15px;
  }
  .buttons {
    padding-top: 12px;
    text-align: right;
  }

  .buttons .btn-cancel,
  .buttons .btn-confir {
    width: 132px;
    height: 36px;
    border-radius: 10px;
    color: black;
    font-weight: bold;
  }
  .buttons .btn-confir {
    margin-left: 20px;
    color: white;
    
    background-color: #1E2A4F;
  }
</style>